import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import '../styles/SignUp.css';
import '../styles/SignUpPage.css';  // Import the CSS for this component

function SignUp() {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [form, setForm] = useState({
        signupCode: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const navigate = useNavigate();

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (form.password !== form.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        try {
            console.log(form.firstName, form.lastName, form.email, form.password);
            const response = await axios.post(`${API_ENDPOINT}/api/register/`, {
                signup_code: form.signupCode,
                email: form.email,
                password: form.password,
                first_name: form.firstName,
                last_name: form.lastName,
            });

            const userType = response.data.user_type;

            const loginResponse = await axios.post(`${API_ENDPOINT}/api/login/`, {
                email: form.email,
                password: form.password,
              });

            localStorage.setItem('token', loginResponse.data.token);
            // Redirect based on user type
            if (userType === 1) { // Student
                navigate('/baseline-plans');
            } else {
                navigate('/');
            }

            window.location.reload();

        } catch (error) {
            console.error(error);
            // Display error message from backend if available
            if (error.response && error.response.data && error.response.data.error) {
                alert(`Error: ${error.response.data.error}`);
            } else {
                alert('An unknown error occurred. Please try again later.');
            }
        }
    };

    return (
        <div className="signup-page">
        <Container className="signup-container">
            <h2 className="center-text">Sign Up</h2>
            <p className="center-text">Already registered? <Link to="/log-in">Sign In</Link></p>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label className="bold-label">First Name</Form.Label>
                    <Form.Control type="text" name="firstName" value={form.firstName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label className="bold-label">Last Name</Form.Label>
                    <Form.Control type="text" name="lastName" value={form.lastName} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label className="bold-label">Email</Form.Label>
                    <Form.Control type="email" name="email" value={form.email} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label className="bold-label">Password</Form.Label>
                    <Form.Control type="password" name="password" value={form.password} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label className="bold-label">Confirm Password</Form.Label>
                    <Form.Control type="password" name="confirmPassword" value={form.confirmPassword} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSignupCode">
                    <Form.Label className="bold-label">Sign-Up Code</Form.Label>
                    <Form.Control
                        type="text"
                        name="signupCode"
                        value={form.signupCode}
                        onChange={handleChange}
                    />
                </Form.Group>

                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Sign Up
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
    );
}

export default SignUp;
